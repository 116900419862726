/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {
  var ajax = null;
  var mapUrl = 'https://www.google.nl/maps/dir//Mariniersweg+9,+3011+NB+Rotterdam/@51.9239668,4.4858354,17z/data=!4m16!1m7!3m6!1s0x47c4335af5cf5675:0xcfdbcd0f87285471!2sMariniersweg+9,+3011+NB+Rotterdam!3b1!8m2!3d51.9239668!4d4.4880241!4m7!1m0!1m5!1m1!1s0x47c4335af5cf5675:0xcfdbcd0f87285471!2m2!1d4.4880241!2d51.9239668';

  function loadMap(container) {
    var styles = [
      {
        "featureType": "road",
        "elementType": "geometry",
        "stylers": [
          {
            "lightness": 100
          },
          {
            "visibility": "simplified"
          }
        ]
      },
      {
        "featureType": "water",
        "elementType": "geometry",
        "stylers": [
          {
            "visibility": "on"
          },
          {
            "color": "#C6E2FF"
          }
        ]
      },
      {
        "featureType": "poi",
        "elementType": "geometry.fill",
        "stylers": [
          {
            "color": "#a0c875",
            "visibility": "off"
          }
        ]
      },
      {
        "featureType": "road",
        "elementType": "geometry.fill",
        "stylers": [
          {
            "color": "#D1D1B8"
          }
        ]
      }
    ];

    var lat = 51.923967;
    var lng = 4.488024;
    var latlng = new google.maps.LatLng( lat, lng );
    var mapOptions = {
      center: { lat: lat, lng: lng},
      scrollwheel: false,
      draggable: false,
      disableDefaultUI : true,
      zoom: 17,
      minZoom: 2,
      styles : styles,
      backgroundColor : '#ffffff'
    };

    var map = new google.maps.Map(document.getElementById(container), mapOptions);

  	var marker = new google.maps.Marker({
  		position : latlng,
  		map : map
  	});
  }

  function contactForm() {
    $('#contact-form').validator().on('submit', function(event) {
      if (!event.isDefaultPrevented() && !$('input[name="b_235bef887d82ddc4c0f9d1424_eb347e006f"]').val()) {
        var data = $(this).serializeArray();
        data.push({
          name: 'action',
          value: 'contact_form'
        });
        data.push({
          name: '_ajax_nonce',
          value: sageAjax.ajax_nonce
        });

        $.ajax({
          type: 'POST',
          url: sageAjax.ajax_url,
          data: data,
          beforeSend: function() {
            $('#contact-form-container form').hide();
            $('#contact-form-container').append('<div class="loader"><div class="loader-inner"></div></div>');
          },
          success: function(response) {
            response = $.parseJSON(response);
            $('#contact-form-container .loader').remove();
            if (response.status === 'success') {
              $("#contact-form-success").show();
            } else {
              $("#contact-form-error").html(response.detail).show();
            }
          }
        });

        event.preventDefault();
      }
    });
  }

  function loadPage() {
    $('.main-header .navbar .nav-item a').on('click', function(event) {
      var $parent = $(this).parent();
      $('.main-header .navbar li').removeClass('active');
      $parent.toggleClass('active');

      if ($parent.hasClass('active')) {
        var name = $(this).data('name');
        ajax = $.ajax({
          type: "GET",
          url: sageAjax.ajax_url,
          data: {
            action: 'load_page',
            name: name,
            _ajax_nonce: sageAjax.ajax_nonce
          },
          beforeSend: function() {
            $('.site-menu .main-page .main-page-inner').html('<div class="loader"><div class="loader-inner"></div></div>');
          },
          success: function(response) {
            $('.site-menu .main-page .main-page-inner').html(response);
            if (name === 'route') {
              $('.site-menu .main-page .main-page-inner').append('<a href="'+mapUrl+'" id="map" target="_blank"></div>');
              loadMap('map');
            }

            if (name === 'contact') {
              contactForm();
            }
          }
        });
      }
      event.preventDefault();
    });
  }

  function loadPages(name, href) {
    var offset = $('#'+name).position();
    var sidebarIntro = false;
    history.pushState({}, '', href);

    $('.sidebar-pages').css('margin-top', $('.sidebar-pages .sidebar-content-container').outerHeight()+105).show();

    if ($('.sidebar-intro').is(':visible')) {
      sidebarIntro = true;
      $('.sidebar-intro, .logo').addClass('fade-out');
      setTimeout(function() {
        $('.sidebar-pages').addClass('slide-up');
        $('.sidebar-pages .sidebar-content-container').scrollTo($('#'+name), 800);
        //window.location.hash = name;
      }, 800);
    }
    if (!sidebarIntro && offset) {
      $('.sidebar-pages .sidebar-content-container').scrollTo($('#'+name), 800);
    }
  }

  function loadArchive(name, href) {
    var offset = $('#'+name).position();
    var sidebarIntro = false;
    history.pushState({}, '', href);
    $('.sidebar-posts').show().css('margin-top', $('.sidebar-posts .sidebar-content-container').outerHeight()+105);

    // If the intro is still visible: remove and slide in text
    if ($('.sidebar-intro').is(':visible')) {
      sidebarIntro = true;
      $('.sidebar-intro, .logo').addClass('fade-out');
      setTimeout(function() {
        $('.sidebar-posts').addClass('slide-up');
        $('.sidebar-posts .sidebar-content-container').scrollTo($('#'+name), 800);
        //window.location.hash = name;
      }, 800);
    }
    if (!sidebarIntro && offset) {
      //window.location.hash = name;
      $('.sidebar-posts .sidebar-content-container').scrollTo($('#'+name), 800);
    }
  }

  function resizeSitemenuBody() {
    $('.main-body').matchHeight({
      target : $('.sidebar-inner')
    });

    $('.main-body .main-page, .main-body .main-opening-times').perfectScrollbar();
  }

  function initSliders() {
    $('.tile-slides.ltr').not('.slick-initialized').slick({
      infinite: true,
      dots: false,
      arrows: false,
      slidesToScroll: 1,
      autoplay: true,
      rtl: false,
      autoplaySpeed: 2000,
      easing : 'ease-out',
      pauseOnHover : false,
      speed : 2000
    });

    $('.tile-slides.rtl').not('.slick-initialized').slick({
      infinite: true,
      dots: false,
      arrows: false,
      slidesToScroll: 1,
      autoplay: true,
      autoplaySpeed: 2000,
      easing : 'ease-out',
      rtl: true,
      pauseOnHover : false,
      speed : 2000
    });

    $('.tiles').on('mouseenter', function() {
      $('.tile-slides').slick('slickPause');
    });

    $('.tiles').on('mouseleave', function() {
      $('.tile-slides').slick('slickPlay');
    });

    $('.tile-entry-overlay, .tile-entry-message-overlay').on('mouseenter', function() {
      var $element = null;
      var position = $(this).data('position');

      // Toggle active state of other overlay
      switch(position) {
        case 1:
          $element = $('.tile-entry-overlay[data-position="1"], .tile-entry-overlay[data-position="4"], .tile-entry-message-overlay[data-position="1"], .tile-entry-message-overlay[data-position="4"]');
        break;
        case 2:
          $element = $('.tile-entry-overlay[data-position="2"], .tile-entry-overlay[data-position="3"], .tile-entry-message-overlay[data-position="2"], .tile-entry-message-overlay[data-position="3"]');
        break;
        case 3:
          $element = $('.tile-entry-overlay[data-position="3"], .tile-entry-overlay[data-position="2"], .tile-entry-message-overlay[data-position="3"], .tile-entry-message-overlay[data-position="2"]');
        break;
        case 4:
          $element = $('.tile-entry-overlay[data-position="4"], .tile-entry-overlay[data-position="1"], .tile-entry-message-overlay[data-position="4"], .tile-entry-message-overlay[data-position="1"]');
        break;
      }
      $('.tile').removeClass('triggered');
      $element.parents('.tile').addClass('triggered');
    });

    $('.tile-entry-overlay, .tile-entry-message-overlay').on('mouseleave', function() {
      $('.tile').removeClass('triggered');
    });
  }

  function mailchimpSignup() {
    $('#mailchimp-signup').validator().on('submit', function(event) {
      if (!event.isDefaultPrevented() && !$('input[name="b_235bef887d82ddc4c0f9d1424_eb347e006f"]').val()) {
        var data = $(this).serializeArray();
        data.push({
          name: 'action',
          value: 'mailchimp_subscribe'
        });
        data.push({
          name: '_ajax_nonce',
          value: sageAjax.ajax_nonce
        });

        $(this).hide();
        $("#mailchimp-message").show();

        $.ajax({
          type: 'POST',
          url: sageAjax.ajax_url,
          data: data,
          success: function(response) {
            $("#mailchimp-message").hide();
            response = $.parseJSON(response);
            if (response.status === 'pending') {
              $("#mailchimp-success").show();
            } else {
              $("#mailchimp-error").html(response.detail).show();
            }
           }
        });
      }
      event.preventDefault();
    });
  }

  function setSidebarContentContainerHeight() {
    // Margin differs on archives versus pages
    var sidebarMargin = null;
    if ($('body').hasClass('page')) {
      sidebarMargin = 115;
    } else {
      sidebarMargin = 110;
    }
    var sidebarHeight = $('.sidebar').outerHeight() - $('.sidebar .header').outerHeight() - sidebarMargin;
    $('.sidebar-content-container').css('height', sidebarHeight);
  }

  function loadBioscopePage() {
    var postsVisible = false;

    $('.tile:not(.last-tile) a').on('click', function(event) {
      $('.tiles').toggleClass('active');
      var $currentTarget = $(event.currentTarget);
      if (!$currentTarget.hasClass('tile-sharing-link')) {
        if (!postsVisible) {
          var name = $(this).parents('.tile').data('name');
          var href = $(this).attr('href');
          loadArchive(name, href);
          postsVisible = true;
          $('.sidebar-content-container').perfectScrollbar();
          setSidebarContentContainerHeight();
        } else {
          $('.sidebar-posts').removeClass('slide-up').hide();
          $('.sidebar-intro, .logo').removeClass('fade-out');
          postsVisible = false;
        }
        event.preventDefault();
      }
    });

    if (window.location.hash) {
      var name = window.location.hash.substr(1);
      var href = $('.tile[data-name="'+name+'"] a.tile-body').attr('href');
      loadArchive(name, href);
    }

    $('.edition-list-container').perfectScrollbar();
  }

  function loadStores(slider) {
    var $ajaxContainer = $('.ajax-container');
    var $ajaxReceiver = $('.ajax-receiver .ajax-receiver-inner');

    $('.nav-item').on('click mouseenter mouseleave', function(event) {
      // If nav-item-winkelen is clicked and store data isnt't already present get the stores
      if ($(this).hasClass('nav-item-winkelen') && !$('.stores').length) {
        ajax = $.ajax({
          type: "GET",
          url: sageAjax.ajax_url,
          data: {
            action: 'load_stores',
            _ajax_nonce: sageAjax.ajax_nonce
          },
          success: function(response) {
            $ajaxContainer.addClass('stores').html('<div class="tiles stores">'+response+'</div>');
          }
        });
      } else if ($ajaxContainer.hasClass('tiles')) {
        // Do not swap when a store is chosen
        //if($(event.target).hasClass('dropdown-toggle')) {
        if(!$(event.target).parents('.dropdown-menu').length) {
          var tiles = $ajaxContainer.html();
          $ajaxReceiver.html(tiles).removeClass('stores');
          $ajaxContainer.removeClass('tiles');

          if (slider) {
            initSliders();
          }
        }
      }
    });

    $('.nav-item-winkelen .dropdown-menu li').on('mouseenter', function() {
      // Should't work on small screens
      if (!$('.navbar-brand').is(':visible')) {
        // If the ajaxContainer is populated with store data swap the HTML in tiles and the container
        if ($ajaxContainer.hasClass('stores')) {
          if (slider) {
            $('.tile-slides').slick('unslick');
          }
          var stores = $ajaxContainer.html();
          $ajaxContainer.html($ajaxReceiver.html()).removeClass('stores').addClass('tiles');
          $ajaxReceiver.html(stores).addClass('stores');
        }
        var name = $(this).data('name');
        $('.stores .store', $ajaxReceiver).removeClass('active');
        $('.store-'+name, $ajaxReceiver).addClass('active');
      }
    });

    $(document).on('click', '.nav-item-winkelen li a, a.store', function(event) {
      $(document).off('hide.bs.dropdown', '.nav-item-winkelen.menu-item-has-children');
    });

    // Show the tiles again when the stores dropdown is closed
    $(document).on('hide.bs.dropdown', '.nav-item-winkelen.menu-item-has-children', function(relatedTarget) {
      if ($ajaxContainer.hasClass('tiles')) {
        var tiles = $ajaxContainer.html();
        $ajaxReceiver.html(tiles).removeClass('stores');
        $ajaxContainer.removeClass('tiles');

        if (slider) {
          initSliders();
        }
      }
    });
  }

  function loadStoresOnPages() {
    var $ajaxContainer = $('.store-slides');

    $('.nav-item').on('click mouseenter mouseleave', function(event) {
      // If nav-item-winkelen is clicked and store data isnt't already present get the stores
      if ($(this).hasClass('nav-item-winkelen') && !$('.store-slides .store').length) {
        ajax = $.ajax({
          type: "GET",
          url: sageAjax.ajax_url,
          data: {
            action: 'load_stores',
            _ajax_nonce: sageAjax.ajax_nonce
          },
          success: function(response) {
            $ajaxContainer.html(response).addClass('active');
          }
        });
      } else if(!$(event.target).parents('.dropdown-menu').length) {
        $ajaxContainer.removeClass('active');
      }
    });

    $('.nav-item-winkelen .dropdown-menu li').on('mouseenter', function() {
      // Should't work on small screens
      if (!$('.navbar-brand').is(':visible')) {
        // If the ajaxContainer is populated with store data swap the HTML in tiles and the container
        if ($ajaxContainer.hasClass('active')) {
          var name = $(this).data('name');
          $('.store', $ajaxContainer).removeClass('active');
          $('.store-'+name, $ajaxContainer).addClass('active');
        } else {
          $ajaxContainer.addClass('active');
        }
      }
    });

    $(document).on('click', '.nav-item-winkelen li a, a.store', function(event) {
      $(document).off('hide.bs.dropdown', '.nav-item-winkelen.menu-item-has-children');
    });

    // Show the content again when the stores dropdown is closed
    $(document).on('hide.bs.dropdown', '.nav-item-winkelen.menu-item-has-children', function(relatedTarget) {
      $ajaxContainer.removeClass('active');
    });
  }

  function paginateTileClusters() {
    var $tileCluster = $('.tile-cluster');
    var tileClusterWidth = 0;

    $tileCluster.each(function() {
      var $tileSlides = $('.tile-slides', this);
      if ($tileSlides.length <= 2) {
        $(this).addClass('tile-cluster-small').css('width', $tileCluster.width()/2);
        $tileSlides.css({
          'float': 'none',
          'width': '100%'
        });
        tileClusterWidth += $tileCluster.width()/2;
      } else {
        $(this).addClass('tile-cluster-wide').css('width', $tileCluster.width());
        tileClusterWidth += $tileCluster.width();
      }
    });
    $('.tiles-inner').css('width', tileClusterWidth);

    if ($tileCluster.length > 1) {
      $('.pagination.next').removeClass('invisible');

      $('.pagination.previous').on('click', function(event) {
        $('.tiles').data('page', $('.tiles').data('page')-1);
        var $element = $(this);
        if (!$element.hasClass('disabled')) {
          var tileClusterStep = $tileCluster.width()/2;
          var tileWrapperMargin = Math.abs(parseInt($('.tiles-wrapper').css('margin-left')));
          var step = Math.floor(tileWrapperMargin - tileClusterStep);
          $element.addClass('disabled');

          if (step === 0) {
            $('.pagination.previous').addClass('invisible');
            $('.pagination.next').removeClass('invisible');
          }

          if (($('.tiles-wrapper').width()-tileWrapperMargin) > step+$tileCluster.width()) {
            $('.pagination.next').removeClass('invisible');
          }
          $('.tiles-wrapper').animate({'margin-left': '-'+step+'px'}, 500, function() {
            $element.removeClass('disabled');
          });
        }
        event.preventDefault();
      });

      $('.pagination.next').on('click', function(event) {
        $('.tiles').data('page', $('.tiles').data('page')+1);
        var $element = $(this);
        if (!$element.hasClass('disabled')) {
          $('.pagination.previous').removeClass('invisible');
          $element.addClass('disabled');

          var tileClusterStep = $tileCluster.width()/2;
          var tileWrapperMargin = Math.abs(parseInt($('.tiles-wrapper').css('margin-left')));
          var step = Math.ceil(tileClusterStep + tileWrapperMargin);

          if (($('.tiles-wrapper').width()-tileWrapperMargin) === step+$tileCluster.width()) {
            $('.pagination.next').addClass('invisible');
          }
          $('.tiles-wrapper').animate({'margin-left': '-'+step+'px'}, 500, function() {
            $element.removeClass('disabled');
          });
        }
        event.preventDefault();
      });
    }

    $(window).resize(function() {
      var availableWidth = $('.ajax-receiver-inner').width();
      tileClusterWidth = 0;

      $tileCluster.each(function() {
        var $tileSlides = $('.tile-slides', this);
        if ($tileSlides.length <= 2) {
          $(this).css('width', availableWidth/2);
          $tileSlides.css({
            'float': 'none',
            'width': '100%'
          });
          tileClusterWidth += availableWidth/2;
        } else {
          $(this).css('width', availableWidth);
          tileClusterWidth += availableWidth;
        }
      });
      $('.tiles-inner').css('width', Math.ceil(tileClusterWidth));

/*      var step = (availableWidth/2)*$('.tiles').data('page');
      $('.tiles-wrapper').css('margin-left', '-'+step+'px');
*/
      $('.tiles-wrapper').css('margin-left', 0);
      $('.pagination.previous').addClass('invisible');
      $('.pagination.next').removeClass('invisible');
    });
  }

  function toggleMenuButtons() {
    $('.menu-button-toggle').on('click', function(event) {
      $(this).parents('ul.menu-buttons').toggleClass('open');
      event.preventDefault();
    });
  }

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        // JavaScript to be fired on all pages
        svg4everybody();
        var isMobile = window.matchMedia("only screen and (max-device-width: 1024px)");
        if (isMobile.matches) {
          window.viewportUnitsBuggyfill.init();
        }

        $('.notice-overlay, .notice-close').on('click', function(event) {
          if (!$(event.target).parents('.notice-content').length) {
            $('.notice-overlay').removeClass('active');
            event.preventDefault();
          }
        });

        toggleMenuButtons();

        if (!$('.navbar-brand').is(':visible')) {
          $('ul.nav li.menu-item-has-children').on('mouseenter', function() {
            $(this).addClass('open').trigger( "show.bs.dropdown" );
          });

          $('ul.nav li.menu-item-has-children').on('mouseleave', function() {
            $(this).removeClass('open').trigger( "hide.bs.dropdown" );
          });
        }

        if ($('.sidebar-tiles').is(':visible')) {
          $('.entry-content-container').perfectScrollbar();
        }

        if ($('.ajax-receiver').is(':visible')) {
          $('.sidebar-content-container').perfectScrollbar();
          setSidebarContentContainerHeight();
        }

        $(document).on('show.bs.dropdown', '.nav-item', function() {
          $('.menu-overlay').fadeIn('fast');
        });

        $(document).on('hide.bs.dropdown', '.nav-item', function() {
          $('.menu-overlay').fadeOut('fast');
        });

        $(window).resize(function() {
          if ($('.sidebar-content-container').length && ($('.ajax-receiver').is(':visible') || $('body').hasClass('post-type-archive-bioscope'))) {
            setSidebarContentContainerHeight();
          }

          resizeSitemenuBody();
        });

        $('.sharing-link.facebook, .sharing-link.twitter').on('click', function(event) {
          event.preventDefault();
          window.open($(this).attr('href'), 'fbShareWindow', 'height=450, width=550, top=' + ($(window).height() / 2 - 275) + ', left=' + ($(window).width() / 2 - 225) + ', toolbar=0, location=0, menubar=0, directories=0, scrollbars=0');
          return false;
        });

        $(document).on('show.bs.dropdown', '.menu-item-has-children', function () {
          var $menu = $('.dropdown-menu', this);
          var $main = $(this).parents('.sidebar');
          var offset = $(this).position();
          if (offset) {
            $menu.css({
              'left' : -offset.left,
              'width' : $main.width()
            });
          }

          $('ul.menu-buttons').removeClass('open');
        });

        $(window).on('resize', function() {
          var $menu = $('.nav-item.open .dropdown-menu');
          var offset = $('.nav-item.open').position();
          if (offset) {
            $menu.css({
              'left' : -offset.left,
              'width' : $menu.parents('.sidebar').width()
            });
          }
        });

        $(document).on('click', '.menu-item-has-children.open a', function(event) {
          if ($(event.target).hasClass('dropdown-toggle') && $(this).parents('.menu-item-has-children').hasClass('nav-item-de-groene-passage')) {

            if ($('body').hasClass('home') && !$('.logo').hasClass('fade-out')) {
            } else {
              window.location.href = $(this).attr('href');
              event.preventDefault();
            }
          }
        });

        var $ajaxContainer = $('.ajax-container');
        var $ajaxReceiver = $('.ajax-receiver .ajax-receiver-inner');

        $('.sidebar-slider, .main-slider').slick({
          infinite: true,
          dots: false,
          arrows: false,
          fade: true,
          slidesToScroll: 1,
          autoplay: true,
          autoplaySpeed: 2000,
          speed : 2000
        });

        $('.main-slider').slick('slickPause');

        setTimeout(function() {
          $('.main-slider').slick('slickPlay');
        }, 3000);

        var $footer = $('#footer');
        $(document).on('click', '.btn-toggle, .menu-buttons .menu-button-menu-toggle', function(event) {
          $footer.toggleClass('active');

          if ($footer.hasClass('active')) {
            ajax = $.ajax({
              type: "GET",
              url: sageAjax.ajax_url,
              data: {
                action: 'load_sitemenu',
                _ajax_nonce: sageAjax.ajax_nonce
              },
              success: function(response) {
                $footer.html(response);

                loadPage();
                mailchimpSignup();
                resizeSitemenuBody();

                $('.sidebar-slider, .main-slider').slick({
                  infinite: true,
                  dots: false,
                  arrows: false,
                  slidesToScroll: 1,
                  fade: true,
                  autoplay: true,
                  autoplaySpeed: 5000,
                  speed: 2000
                });

              }
            });
          } else {
            setTimeout(function() {
              $footer.html('<div class="loader"><div class="loader-inner"></div></div>');
            }, 500);
          }
          event.preventDefault();
        });
      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
      }
    },
    // Home page
    'home': {
      init: function() {
        initSliders();

        loadStores(true);

        if (!$('.navbar-brand').is(':visible')) {
          $(document).on('click', '.navigation .nav-item-de-groene-passage li a', function(event) {
            var name = $(this).data('name');
            var href = $(this).data('href');
            $('#footer').removeClass('active');
            loadPages(name, href);
            event.preventDefault();
          });

          if (window.location.hash) {
            var name = window.location.hash.substr(1);
            var href = $('.menu-item .regular-item[data-name="'+name+'"]').data('href');
            loadPages(name, href);
          }
        }
      },
      finalize: function() {
        // Open social media tab on page load
        if (!$('.sidebar-intro').hasClass('fade-out')) {
          $('ul.menu-buttons').addClass('open');
        }
      }
    },
    'archive': {
      init: function() {
        loadStores(false);
        if (!$('body').hasClass('post-type-archive-bioscope')) {
          paginateTileClusters();
        }

        // Do not fire on blog archives
        if (!$('body').hasClass('blog') && !$('body').hasClass('post-type-archive-bioscope')) {
          if (!$('.navbar-brand').is(':visible')) {
            $(document).on('click', '.tile a', function(event) {
              var $currentTarget = $(event.currentTarget);

              if (!$currentTarget.hasClass('tile-sharing-link')) {
                var name = $(this).parents('.tile').data('name');
                var href = $(this).attr('href');
                loadArchive(name, href);
                event.preventDefault();
              }
            });

            if (window.location.hash) {
              var name = window.location.hash.substr(1);
              var href = $('.tile[data-name="'+name+'"] a.tile-body').attr('href');
              loadArchive(name, href);
            }
          }
        }

        $(document).on('mouseenter', '.tile.news', function() {
          $('.tile-colour', this).css('margin-top', -$(this).outerHeight());
        });

        $(document).on('mouseleave', '.tile.news', function() {
          $('.tile-colour', this).css('margin-top', 0);
        });

        $(document).on('mouseenter', '.tile.event, .tile.post', function() {
          $('.tile-image', this).css('margin-top', -$(this).outerHeight());
        });

        $(document).on('mouseleave', '.tile.event, .tile.post', function() {
          $('.tile-image', this).css('margin-top', 0);
        });
      }
    },
    'single_bioscope': {
      init: function() {
        loadBioscopePage();

        loadArchive(pageName, null);
        $('.sidebar-content-container').perfectScrollbar();
        setSidebarContentContainerHeight();
      }
    },
    'post_type_archive_bioscope': {
      init: function() {
        loadBioscopePage();
      }
    },
    'page': {
      init: function() {

        loadStores(true);

        $('.sidebar-content').css('margin-top', $('.sidebar-content-container').outerHeight()+115);
        setTimeout(function() {
          $('.sidebar-content').addClass('slide-up');
          if ($('.sidebar-content article').hasClass('active')) {
            var offset = $('.sidebar-content article.active').position();
            $('.sidebar-content-container').scrollTop($('.scroll-content').scrollTop() + offset.top + 1);
            $('.sidebar-content-container').perfectScrollbar('update');
          }
        }, 800);

        if ($('.tile-slides.ltr').length > 0 && $('.tile-slides.rtl').length > 0) {
          initSliders();
        }

        if (!$('.navbar-brand').is(':visible')) {
          $('ul.dropdown-menu li a').on('click', function(event) {
            var name = $(this).data('name');
            if ($('#'+name).length) {
              $('.sidebar-content-container').scrollTo($('#'+name), 800);
              event.preventDefault();
            }
          });
        }
      }
    },
    'single': {
      init: function() {
        $(document).on('mouseenter', '.tile.post', function() {
          $('.tile-image', this).css('margin-top', -$(this).outerHeight());
        });

        $(document).on('mouseleave', '.tile.post', function() {
          $('.tile-image', this).css('margin-top', 0);
        });

        setTimeout(function() {
          var entryHeight = $('.main article').outerHeight()-$('.entry-header').outerHeight()-165;
          $('.entry-content-container').css('max-height', entryHeight+'px');
        }, 100);

        setTimeout(function() {
          var entryHeight = $('.main article').outerHeight()-$('.entry-header').outerHeight()-165;
          $('.entry-content-container').css('max-height', entryHeight+'px');
        }, 500);
      }
    },
    'single_store': {
      init: function() {
        if ($('.sidebar .sidebar-tiles').is(':visible')) {
          initSliders();
          loadStoresOnPages();

          $('.sidebar .sidebar-tiles').slick({
            infinite: true,
            dots: false,
            arrows: false,
            slidesToScroll: 1,
            fade: true,
            autoplay: true,
            autoplaySpeed: 3000,
            easing : 'ease-out',
            pauseOnHover : false,
            speed : 2000
          });

          setTimeout(function() {
            var entryHeight = $('.main article').outerHeight()-$('.entry-header').outerHeight()-$('.entry-footer').outerHeight()-$('.entry-meta').outerHeight()-65;
            $('.entry-content-container').css('max-height', entryHeight+'px');
          }, 100);

          setTimeout(function() {
            var entryHeight = $('.main article').outerHeight()-$('.entry-header').outerHeight()-$('.entry-footer').outerHeight()-$('.entry-meta').outerHeight()-65;
            $('.entry-content-container').css('max-height', entryHeight+'px');
          }, 500);
        }
      }
    },
    'jubileum': {
      init: function() {
        if ($('.sidebar .sidebar-tiles').is(':visible')) {
          $('.sidebar .sidebar-tiles').slick({
            infinite: true,
            dots: false,
            arrows: false,
            slidesToScroll: 1,
            fade: true,
            autoplay: true,
            autoplaySpeed: 3000,
            easing : 'ease-out',
            pauseOnHover : false,
            speed : 2000
          });
        }

        $(document).on('mouseenter', '.tile.jubilee', function() {
          $('.tile-image', this).css('margin-top', -$(this).outerHeight());
        });

        $(document).on('mouseleave', '.tile.jubilee', function() {
          $('.tile-image', this).css('margin-top', 0);
        });

        if ($('.sidebar .sidebar-tiles').is(':visible')) {
          setTimeout(function() {
            var entryHeight = $('.main article').outerHeight()-$('.entry-header').outerHeight()-165;
            $('.entry-content-container').css('max-height', entryHeight+'px');
          }, 100);

          setTimeout(function() {
            var entryHeight = $('.main article').outerHeight()-$('.entry-header').outerHeight()-165;
            $('.entry-content-container').css('max-height', entryHeight+'px');
          }, 500);
        }
      }
    },
    'contact': {
      init: function() {
        contactForm();
      }
    },
    'route': {
      init: function() {
        $('.sidebar-content-container .page .entry-content').append('<a href="'+mapUrl+'" id="map" target="_blank" style="display: block; width: 100%; height: 300px;"></a>');
        loadMap('map');
      }
    },
    'nieuwsbrief': {
      finalize: function() {
        mailchimpSignup();
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
